import { doApi } from "./index";

export type UserRole = 'manager';

export interface User {
  userId: string;
  email: string;
  userName: string;
  roles: UserRole[];
}

export interface UserList {
  items: Array<User>;
}

export const fetchUser = async (): Promise<User> => {
  return doApi({
    method: 'GET',
    path: '/users/me',
  });
}

export const fetchUsers = async (): Promise<UserList> => {
  return doApi({
    method: 'GET',
    path: '/users',
  });
}
