import React from "react";

import styles from "./CalendarCell.module.css";
import { DailyReport } from '../service/api/dailyreports'
interface CalendarCellProps {
  weekNumber: number;
  cellType: CellType;
  text: string;
  dailyReport?: DailyReport;
  onclick: () => void;
}

export type CellType = 'saved' | 'tempsaved' | 'sunday' | 'saturday' | 'outside' | 'default';

export const CalendarCell: React.FC<CalendarCellProps> = (props: CalendarCellProps) => {
  return (
    <div
      className={`${styles.cell} ${styles[props.cellType]}`}
      onClick={() => props.onclick()}
    >
      <div className={styles['inner-cell']}>
        <div className={styles.summary}>
          {props.text}
        </div>
        <div className={styles.body}>
          {/* {
            props.dailyReport ?
            `${props.dailyReport.startTime} 〜 ${props.dailyReport.endTime}` :
            <></>
          } */}
        </div>
      </div>
    </div>
  )
}

export default CalendarCell;
