import { doApi } from "./index";

export interface DailyReportMonthlyConfirm {
  isConfirmedMonthlyReport: boolean;
}

export interface MonthlyReport {
  monthlyReportId?: string;
  year: number;
  month: number;
  userId: string;
}

export interface MonthlyReportList {
  items: MonthlyReport[];
}

// 月報を作成する
export const createMonthlyReport = async (monthlyReport: MonthlyReport): Promise<MonthlyReport> => {
  return doApi({
    path: '/monthlyreports',
    method: 'POST',
    data: monthlyReport,
  });
}

// 月報一覧を作成する（年月ごとに通常一つのみ）
export const listMonthlyReports = async (year: string, month: string): Promise<MonthlyReportList> => {
  return doApi({
    method: 'GET',
    path: '/monthlyreports',
    data: {
      year,
      month,
    },
  });
}

// 月報の内容を削除する
export const deleteMonthlyReport = async (monthlyReportId: string): Promise<string> => {
  return doApi({
    method: 'DELETE',
    path: `/monthlyreports/${monthlyReportId}`,
  });
}
