import {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import {
  ArrowDownward as ArrowDownwardIcon,
  ArrowUpward as ArrowUpwardIcon,
  SwapVert as SwapVertIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from '@material-ui/icons';
import dayjs from 'dayjs';
import { Header } from '../../../components/Header';
import { SideMenu } from '../../../components/SideMenu';
import { PageMain } from '../../../components/PageMain';
import './MattersPage.css';
import {
  fetchMatters,
  updateMatter,
  Matter,
} from '../../../service/api/matters';


type MatterSorter = (a: Matter, b: Matter) => number;

function useMatters(sorter: Array<MatterSorter>): [Array<Matter>, Dispatch<SetStateAction<Array<Matter>>>] {
  const [matters, setMatters] = useState<Array<Matter>>([]);
  useEffect(() => {
    (async () => {
      const v = await fetchMatters({
        includeHidden: true,
      });
      setMatters(v.items);
    })();
  }, []);
  if (sorter.length === 0) {
    return [matters, setMatters];
  }
  return [matters.sort(sorter[0]), setMatters];
}

type SortDir = (fn: MatterSorter) => MatterSorter;

interface SorterIndicatorProps {
  onClick?: (dir: SortDir) => void;
}

function SorterIndicator(props: SorterIndicatorProps) {
  const [dir, setDir] = useState<'nop' | 'up' | 'down'>('nop');
  const handleToUp = () => {
    setDir('up');
    !!props.onClick && props.onClick((fn: MatterSorter) => {
      return fn;
    });
  };
  const handleToDown = () => {
    setDir('down');
    !!props.onClick && props.onClick((fn: MatterSorter) => {
      return (a: Matter, b: Matter) => {
        return -fn(a, b);
      };
    });
  };
  switch (dir) {
    case 'up':
      return (
        <span onClick={() => handleToDown()}>
          <ArrowUpwardIcon />
        </span>
      );
    case 'down':
      return (
        <span onClick={() => handleToUp()}>
          <ArrowDownwardIcon />
        </span>
      );
    default:
      return (
        <span onClick={() => handleToUp()}>
          <SwapVertIcon />
        </span>
      );
  }
}

function sorterMatterCode(a: Matter, b: Matter) {
  return a.matterCode.localeCompare(b.matterCode);
}

function sorterMatterName(a: Matter, b: Matter) {
  return a.matterName.localeCompare(b.matterName);
}

function sorterSalesMonth(a: Matter, b: Matter) {
  const x = dayjs(a.salesMonth, 'YYYY/M');
  const y = dayjs(b.salesMonth, 'YYYY/M');
  return x.unix() - y.unix();
}

function sorterStatusCode(a: Matter, b: Matter) {
  return a.statusCode.localeCompare(b.statusCode);
}

interface MattersPageMainProps {
}

function MattersPageMain(props: MattersPageMainProps) {
  // 関数を直接stateで管理すると、sort時に挙動がおかしくなるので、arrayで指定
  // （undefinedやsorterが、compareFnに渡されてくる）
  const [sorter, setSorter] = useState<Array<MatterSorter>>([]);
  const [matters, setMatters] = useMatters(sorter);
  const handleToggleVisibility = (v: Matter) => {
    (async () => {
      v.hidden = !v.hidden;
      const v2 = await updateMatter(v);
      setMatters(matters.map(x => {
        return (x.matterId === v2.matterId)
          ? v2
          : x;
      }));
    })();
  };
  return (
    <div>
      <div className="matter-list">
        <>
          <div className="header">
            案件コード
            <SorterIndicator
              onClick={(dir) => setSorter([dir(sorterMatterCode)])}
              />
          </div>
          <div className="header">
            案件名
            <SorterIndicator
              onClick={(dir) => setSorter([dir(sorterMatterName)])}
              />
          </div>
          <div className="header">
            売上月
            <SorterIndicator
              onClick={(dir) => setSorter([dir(sorterSalesMonth)])}
              />
          </div>
          <div className="header">
            案件ステータス
            <SorterIndicator
              onClick={(dir) => setSorter([dir(sorterStatusCode)])}
              />
          </div>
          <div className="header">
            &nbsp;
          </div>
        </>
        {matters.map(matter => (
          <Fragment key={matter.matterId}>
            <div className="item code">{matter.matterCode}</div>
            <div className="item name">{matter.matterName}</div>
            <div className="item date">{matter.salesMonth}</div>
            <div className="item code">{matter.statusCode}</div>
            <div className="item action">
              <button onClick={() => handleToggleVisibility(matter)}>
                {!!matter.hidden ? (
                  <VisibilityOffIcon />
                ) : (
                  <VisibilityIcon />
                )}
              </button>
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  );
}

export function MattersPage() {
  const [sideMenuOpen, setSideMenuOpen] = useState<boolean>(false);

  return (
    <div className="MattersPage">
      <Header
        title="案件管理"
        onMenuButtonClick={() => setSideMenuOpen(!sideMenuOpen)}
        />
      <SideMenu
        open={sideMenuOpen}
        onCloseClick={() => setSideMenuOpen(false)}
        />
      <PageMain content={<MattersPageMain />} />
    </div>
  );
}
