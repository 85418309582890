import React, {
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import dayjs from "dayjs";

import { AuthContext } from "../contexts/AuthProvider";
import {
  DailyReportModal,
  GenericTemplate,
  Calendar
} from "../components";
import {
  DailyReport,
  listDailyReports,
} from '../service/api/dailyreports'
import {
  MonthlyReport,
  listMonthlyReports,
  createMonthlyReport,
  deleteMonthlyReport
} from '../service/api/monthlyreports'
import {
  AttendanceType,
  fetchAttendanceTypes,
} from '../service/api/attendances'
import {
  Matter,
  fetchMatters,
  syncMatters,
} from '../service/api/matters'
import {
  WorkItem,
  fetchWorkItems,
} from '../service/api/workitems'

import {
  CssBaseline,
  Container,
  Button,
  IconButton,
} from '@material-ui/core';
import {
  makeStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import firebase from 'firebase/compat/app';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      paddingTop: "0px"
    },
    sunday: {
      color: theme.palette.secondary.main,
    },
    saturday: {
      color: theme.palette.primary.main,
    },
    day: {
      color: theme.palette.text.primary,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      // height: '20em'
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '0px solid #000',
      borderRadius: '1em',
      boxShadow: theme.shadows[5],
      // padding: theme.spacing(2, 4, 3),
      padding: '2em',
      minHeight: '50em',
      maxHeight: '80vh',
      minWidth: '70em',
      maxWidth: '70em',
      marginBlockStart: '0em'

    },
    dialogPaper: {
      minHeight: '800vh',
      maxHeight: '800vh',
    },
  })
);

// カレンダー日付用 Custom Hook
function useCalendarDate(initialYear?: number, initialMonth?: number, initialDate?: number) {
  const now = dayjs();
  const [year, setYear] = useState(initialYear ?? now.year());
  const [month, setMonth] = useState(initialMonth ?? now.month() + 1);
  const [date, setDate] = useState(initialDate ?? now.date());

  return {
    year,
    month,
    date,
    setYear,
    setMonth,
    setDate
  }
}

// 日報用 Custom Hook
function useDailyReport(initialState: DailyReport): [DailyReport, (dailyReport: DailyReport) => void] {
  const [state, setState] = useState<DailyReport>(initialState);

  const setDailyReport = React.useCallback((dailyReport: DailyReport) => {
    setState(dailyReport);
  }, []);
  return [state, setDailyReport];
}

interface MonthlyReportStateLoading {
  state: 'loading';
}

interface MonthlyReportStateConfirmed {
  state: 'confirmed';

  value: MonthlyReport;
}

interface MonthlyReportStateNotConfirmed {
  state: 'notConfirmed';
}

type MonthlyReportState = MonthlyReportStateLoading
                        | MonthlyReportStateConfirmed
                        | MonthlyReportStateNotConfirmed;

interface MonthlyReportActionReload {
  type: 'reload';
}

interface MonthlyReportActionReloaded {
  type: 'reloaded';

  value?: MonthlyReport;
}

interface MonthlyReportActionConfirm {
  type: 'confirm';
}

interface MonthlyReportActionConfirmed {
  type: 'confirmed';

  value: MonthlyReport;
}

interface MonthlyReportActionUnconfirm {
  type: 'unconfirm';
}

interface MonthlyReportActionUnconfirmed {
  type: 'unconfirmed';
}

type MonthlyReportAction = MonthlyReportActionReload
                         | MonthlyReportActionReloaded
                         | MonthlyReportActionConfirm
                         | MonthlyReportActionConfirmed
                         | MonthlyReportActionUnconfirm
                         | MonthlyReportActionUnconfirmed;

function monthlyReportReducer(state: MonthlyReportState, action: MonthlyReportAction): MonthlyReportState {
  switch (action.type) {
    case 'reload': // fallthrough
    case 'confirm': // fallthrough
    case 'unconfirm':
      return { state: 'loading' };
    case 'reloaded':
      if (!!action.value) {
        return { state: 'confirmed', value: action.value };
      } else {
        return { state: 'notConfirmed' };
      }
    case 'confirmed':
      return { state: 'confirmed', value: action.value };
    case 'unconfirmed':
      return { state: 'notConfirmed' };
    default:
      throw new Error(`unknown action: ${JSON.stringify(action)}`);
  }
}

async function getMonthlyReport(yearMonth: dayjs.Dayjs): Promise<MonthlyReport|undefined> {
  const res = await listMonthlyReports(yearMonth.format("YYYY"), yearMonth.format("MM"));
  return res.items.shift();
}

export const CalendarPage: React.FC<{}>　= () => {
  const { user } = useContext(AuthContext).authState;

  const [dailyReport, setDailyReport] = useDailyReport({} as DailyReport);
  const [monthlyReport, monthlyReportDispatch] = useReducer(monthlyReportReducer, {
    state: 'loading',
  });
  const { year, month, date, setYear, setMonth, setDate } = useCalendarDate();

  const [dailyReportList, setDailyReportList] = useState<DailyReport[]>([]);
  const [matters, setMatters] = useState<Matter[]>([]);
  const [workItems, setWorkItems] = useState<WorkItem[]>([]);
  const [attendanceTypes, setAttendanceTypes] = useState<AttendanceType[]>([]);

  useEffect(() => {
    monthlyReportDispatch({ type: 'reload' });
    (async () => {
      // 選択中の年月
      const yearMonth = dayjs().year(year).month(month - 1)
      // 日報一覧取得
      const dailyReports = await listDailyReports(
        yearMonth.startOf('month').format("YYYY-MM-DD"),
        yearMonth.add(1, 'month').startOf('month').format("YYYY-MM-DD")
      );
      setDailyReportList(dailyReports.items);

      // 日報確定取得
      const monthlyReport = await getMonthlyReport(yearMonth);
      monthlyReportDispatch({ type: 'reloaded', value: monthlyReport });
    })();
  }, [year, month]);

  useEffect(() => {
    (async () => {
      // 案件一覧取得
      const matters = await fetchMatters();
      setMatters(matters.items);
      // 作業項目一覧取得
      const workItems = await fetchWorkItems();
      setWorkItems(workItems.items);
      // 勤務種別一覧取得
      const attendanceTypes = await fetchAttendanceTypes();
      setAttendanceTypes(attendanceTypes.items);
    })();
  }, []);

  const classes = useStyles();
  const [modalOpen, setModalOpen] = React.useState(false);

  // カレンダーセルのクリック
  const createDefaultDailyReport = (date: dayjs.Dayjs): DailyReport => {
    const prev = dailyReportList
      // 前日までの既存データを残す
      .filter((v) => v.date < date.format('YYYY-MM-DD'))
      // 出勤（=案件入力のあるもの）の既存データのみを対象とする
      .filter((v) => v.actualWorks.length > 0)
      // 日付順にソート
      .sort((a, b) => a.date.localeCompare(b.date))
      // 最新日付のものを取得
      .pop();
    if (!prev) {
      return {} as DailyReport;
    }
    return {
      ...prev,
      dailyReportId: undefined,
    };
  };
  const handleClick = (year: number, month: number, date: number ) => {
    const currentDate = dayjs().year(year).month(month - 1).date(date)
    const v = dailyReportList.find(v => v.date === currentDate.format("YYYY-MM-DD"));
    if (!!v) {
      setDailyReport(v);
    } else {
      // 新規入力のため、前日（最新の入力状況）を引き継ぐ
      setDailyReport(createDefaultDailyReport(currentDate));
    }
    setYear(year);
    setMonth(month);
    setDate(date);
    setModalOpen(true);
  }
  const handleModalClose = () => {
    setModalOpen(false);
  };
  const handleModalCreateOrUpdate = (data: DailyReport) => {
    const l = dailyReportList.filter((v) => v.dailyReportId !== data.dailyReportId);
    l.push(data);
    setDailyReportList(l);
  };
  const handleModalDelete = (data: DailyReport) => {
    const l = dailyReportList.filter((v) => v.dailyReportId !== data.dailyReportId);
    setDailyReportList(l);
  };

  const addMonth = () => {
    const yearMonth = dayjs().year(year).month(month - 1).add(1, 'month')
    setYear(yearMonth.year());
    setMonth(yearMonth.month() + 1);
  }

  const subtractMonth = () => {
    const yearMonth = dayjs().year(year).month(month - 1).subtract(1, 'month')
    setYear(yearMonth.year());
    setMonth(yearMonth.month() + 1);
  }

  const handleConfirmMonthlyReport = () => {
    monthlyReportDispatch({type: 'confirm'});
    (async () => {
      const v = await createMonthlyReport({
        userId: user!.userId,
        year,
        month,
      });
      monthlyReportDispatch({type: 'confirmed', value: v});
    })();
  }
  const handleUnconfirmMonthlyReport = () => {
    if (monthlyReport.state !== 'confirmed') {
      throw new Error(`unable to unconfirm with monthlyReport.state === ${monthlyReport.state}`);
    }
    monthlyReportDispatch({ type: 'unconfirm' });
    (async () => {
      await deleteMonthlyReport(monthlyReport.value.monthlyReportId!);
      monthlyReportDispatch({ type: 'unconfirmed' });
    })();
  }

  const [enableMattersSyncButton, setEnableMattersSyncButton] = useState(user!.roles.includes('manager'));
  const handleSyncMatters = () => {
    setEnableMattersSyncButton(false);
    (async () => {
      try {
        // 外部テーブル（Sheets）にクエリかける必要があるため、ログインユーザのGoogleアクセストークンをAPIに渡して、処理してもらう
        const provider = new firebase.auth.GoogleAuthProvider();
        provider.addScope('https://www.googleapis.com/auth/bigquery');
        provider.addScope('https://www.googleapis.com/auth/drive.readonly');
        const result = await firebase.auth().signInWithPopup(provider);
        if (!result.credential) {
          throw new Error('no google credentials found');
        }
        // XXX: ちょっと無理矢理だけど強制変換
        // 本来なら型チェックとかしたほうが良い
        const credential: firebase.auth.OAuthCredential = result.credential;
        if (!credential.accessToken) {
          throw new Error('no google accessToken found');
        }

        await syncMatters({
          accessToken: credential.accessToken,
        });
        const matters = await fetchMatters();
        setMatters(matters.items);
      }
      finally {
        setEnableMattersSyncButton(true);
      }
    })();
  };

  return (
    <GenericTemplate title="日報カレンダー">
      <div className={classes.root}>
        <CssBaseline />
        <div style={{display: "flex", justifyContent: "center", position: "relative"}}>
          <div>
            <IconButton onClick={subtractMonth}>
              <KeyboardArrowLeftIcon />
            </IconButton>
            <Button>
              {`${year}年 ${month}月`}
            </Button>
            <IconButton onClick={addMonth}>
              <KeyboardArrowRightIcon />
            </IconButton>
          </div>
          <div style={{position: 'absolute', right: 0}}>
            <Button variant="outlined" onClick={handleSyncMatters} disabled={!enableMattersSyncButton}>
              案件情報同期
            </Button>
            {monthlyReport.state === 'loading' ? (
              <Button variant="outlined" disabled>状態取得中...</Button>
            ) : monthlyReport.state === 'confirmed' ? (
              <Button variant="outlined" color='secondary' onClick={handleUnconfirmMonthlyReport}>月報確定済</Button>
            ) : (
              <Button variant="outlined" color='primary' onClick={handleConfirmMonthlyReport}>月報確定</Button>
            )}
          </div>
        </div>
        <Container maxWidth="xl" style={{padding: 0}}>
          <Calendar
            date={{year, month, date}}
            dailyReportList={dailyReportList}
            onclick={handleClick}
          />
        </Container>
        <DailyReportModal
          open={modalOpen}
          handleClose={handleModalClose}
          onCreate={handleModalCreateOrUpdate}
          onUpdate={handleModalCreateOrUpdate}
          onDelete={handleModalDelete}
          dailyReport={dailyReport}
          dailyReportList={dailyReportList}
          date={{year, month, date}}
          matters={matters}
          workItems={workItems}
          attendanceTypes={attendanceTypes}
        />
      </div>
    </GenericTemplate>
  );
};

export default CalendarPage;
