import {
  useState,
  useEffect,
  useRef,
} from 'react';
import dayjs from "dayjs";
import {
  Button,
  IconButton,
} from '@material-ui/core';
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
} from '@material-ui/icons';


export interface MonthSelectorProps {
  year?: number;

  // 0-11
  month?: number;

  onChange?: (year: number, month: number) => void;
}

export function MonthSelector(props: MonthSelectorProps) {
  const [date, setDate] = useState(() => {
    let v = dayjs();
    if (!!props.year) {
      v = v.year(props.year);
    }
    if (!!props.month) {
      v = v.month(props.month);
    }
    return v.toDate();
  });
  const onChangeHandler = useRef(props.onChange);

  const handlePrevMonth = () => {
    const value = dayjs(date).subtract(1, 'month').toDate();
    setDate(value);
  };
  const handleNextMonth = () => {
    const value = dayjs(date).add(1, 'month').toDate();
    setDate(value);
  };

  useEffect(() => {
    const fn = onChangeHandler.current;
    if (!!fn) {
      fn(date.getFullYear(), date.getMonth());
    }
  }, [date]);

  return (
    <div>
      <IconButton onClick={handlePrevMonth}>
        <KeyboardArrowLeftIcon />
      </IconButton>
      <Button>
        {`${date.getFullYear()}年 ${date.getMonth() + 1}月`}
      </Button>
      <IconButton onClick={handleNextMonth}>
        <KeyboardArrowRightIcon />
      </IconButton>
    </div>
  );
}
