import { Link } from "react-router-dom";
import {
  Assessment as AssessmentIcon,
  Create as CreateIcon,
  Filter as FilterIcon,
  Close as CloseIcon,
} from '@material-ui/icons';


interface SideMenuProps {
  open?: boolean;

  onCloseClick?: () => void;
}

export function SideMenu(props: SideMenuProps) {
  const handleCloseClick = () => {
    !!props.onCloseClick && props.onCloseClick();
  };

  return (
    <nav className={!!props.open ? "SideMenu open" : "SideMenu close"}>
      <header>
        <button onClick={handleCloseClick}>
          <CloseIcon />
        </button>
      </header>
      <div className="container">
        <div className="category-label">日報登録</div>
        <ul>
          <li className="menu-item">
            <Link to="/calendars">
              <div className="menu-item-icon">
                <CreateIcon />
              </div>
              日報登録
            </Link>
          </li>
          <li className="menu-item">
            <Link to="/analytics">
              <div className="menu-item-icon">
                <AssessmentIcon />
              </div>
              日報分析
            </Link>
          </li>
        </ul>

        <div className="category-label">管理メニュー</div>
        <ul>
          <li className="menu-item">
            <Link to="/admin/matters">
              <div className="menu-item-icon">
                <FilterIcon />
              </div>
              案件管理
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
}
