import React, { useContext, useState } from "react";
import clsx from "clsx";

import { Link } from "react-router-dom";
// import * as colors from "@material-ui/core/colors";
import {
  ThemeProvider,
  createTheme,
  makeStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";

import {
  AuthContext
} from "../contexts/AuthProvider";
import { Copyright }  from "./Copyright";
import {
  CssBaseline,
  Drawer,
  Box,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  Container,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  IconButton,
  Button,
} from "@material-ui/core";

import {
  Menu,
  ChevronLeft,
  EventNote,
  ExpandLess,
  ExpandMore,
  Create,
  Assessment,
  Filter,
  Settings,
} from "@material-ui/icons";

const drawerWidth = 240;

const theme = createTheme({
  typography: {
    fontFamily: [
      "Noto Sans JP",
      "Lato",
      "游ゴシック Medium",
      "游ゴシック体",
      "Yu Gothic Medium",
      "YuGothic",
      "ヒラギノ角ゴ ProN",
      "Hiragino Kaku Gothic ProN",
      "メイリオ",
      "Meiryo",
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: { main: '#3367D6' }, // テーマの色
  },
  props: {
    MuiList: {
      dense: true
    },
  }
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    toolbar: {
      paddingRight: 24,
      // minHeight: '36px'
    },
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar,
    },
    appBar: {
      backgroundColor: "black",
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: "none",
    },
    title: {
      flexGrow: 1,
    },
    pageTitle: {
      marginBottom: theme.spacing(1),
    },
    drawerPaper: {
      position: "relative",
      whiteSpace: "nowrap",
      width: drawerWidth,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
    },
    link: {
      textDecoration: "none",
      color: theme.palette.text.secondary,
    },
    whitecolor: {
      color: 'white',
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
    temp_hide: {
      display:  "none",
    }
  })
);

export interface GenericTemplateProps {
  children: React.ReactNode;
  title: string;
}

export const GenericTemplate: React.FC<GenericTemplateProps> = ({
  children,
  title,
}) => {
  const classes = useStyles();
  const { authState, dispatch } = useContext(AuthContext);
  const [open, setOpen] = useState<boolean>(false);
  const [dailyReportOpen, setDailyReportOpen] = useState<boolean>(true)
  const [adminOpen, setAdminOpen] = useState<boolean>(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDailyReportClick = () => {
    setDailyReportOpen(!dailyReportOpen);
  }

  const logoutHandler = () => {
    dispatch({ type: "logout" });
  }

  const adminMenuEnabled = authState.user!.roles.includes('manager');

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={clsx(classes.appBar, open && classes.appBarShift)}
        >
          <Toolbar className={classes.toolbar} /*variant="dense"*/>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              className={clsx(classes.menuButton,　open && classes.menuButtonHidden)}
            >
              <Menu />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              {title}
            </Typography>
            <div>
              ようこそ、{authState.user?.userName}様&nbsp;&nbsp;&nbsp;
              <Button
                onClick={logoutHandler}
                className={clsx(classes.link, classes.whitecolor)}
              >
                ログアウト
              </Button>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          classes={{　paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose)　}}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeft />
            </IconButton>
          </div>
          <Divider />
          <List>
            <ListItem button onClick={handleDailyReportClick}>
              <ListItemIcon><EventNote /></ListItemIcon>
              <ListItemText primary="日報登録" />
              {dailyReportOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>

            <Collapse in={dailyReportOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link to="/calendars" className={classes.link}>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <Create />
                    </ListItemIcon>
                    <ListItemText primary="日報登録" />
                  </ListItem>
               </Link>
               <Link to="/analytics" className={classes.link}>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <Assessment />
                    </ListItemIcon>
                  <ListItemText primary="日報分析" />
                </ListItem>
                </Link>
              </List>
            </Collapse>
{/*
            <ListItem button onClick={handleClick}>
              <ListItemIcon>
                <EventNote />
              </ListItemIcon>
              <ListItemText primary="日報管理" />
              {open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open} timeout="auto" className={classes.temp_hide} unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <Search />
                  </ListItemIcon>
                  <ListItemText primary="日報一覧" />
                </ListItem>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <Assessment />
                  </ListItemIcon>
                  <ListItemText primary="日報分析" />
                </ListItem>
              </List>
            </Collapse>
*/}
            <ListItem button
              onClick={() => setAdminOpen(!adminOpen)}
              className={adminMenuEnabled ? '' : classes.temp_hide}
              >
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              <ListItemText primary="管理メニュー" />
              {adminOpen ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={adminOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Link to="/admin/matters" className={classes.link}>
                  <ListItem button className={classes.nested}>
                    <ListItemIcon>
                      <Filter />
                    </ListItemIcon>
                    <ListItemText primary="案件管理" />
                  </ListItem>
                </Link>
{/*
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <Create />
                  </ListItemIcon>
                  <ListItemText primary="案件登録" />
                </ListItem>
                <ListItem button className={classes.nested}>
                  <ListItemIcon>
                    <Assessment />
                  </ListItemIcon>
                  <ListItemText primary="日報分析" />
                </ListItem>
*/}
              </List>
            </Collapse>
{/*
            <Link to="/reportregisters" className={`${classes.link} ${classes.temp_hide}`}>
              <ListItem button>
                <ListItemIcon>
                  <Feedback />
                </ListItemIcon>
                <ListItemText primary="通知" />
              </ListItem>
            </Link>

            <Link to="/reportregisters" className={`${classes.link} ${classes.temp_hide}`}>
              <ListItem button>
                <ListItemIcon>
                  <Settings />
                </ListItemIcon>
                <ListItemText primary="設定" />
              </ListItem>
            </Link>
*/}
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container} style={{paddingTop: 0}}>
            {children}
            <Box pt={3}>
              <Copyright />
            </Box>
          </Container>
        </main>
      </div>
    </ThemeProvider>
  );
};
export default GenericTemplate;
