import {
  useCallback,
  ChangeEvent,
} from 'react';
import {
  User,
} from '../service/api/users';


export interface UserSelectorProps {
  items: Array<User & { selected?: boolean }>;

  onChange?: (v: User) => void;
}

export function UserSelector(props: UserSelectorProps) {
  const format = useCallback((v: User) => {
    return `${v.email}`;
  }, []);
  const handleChange = useCallback((evt: ChangeEvent<HTMLSelectElement>) => {
    const v = props.items.find(v => v.userId === evt.target.value);
    console.log('user changed', v);
    !!props.onChange && !!v && props.onChange(v);
  }, [props]);

  return (
    <select
      name="UserSelector"
      onChange={handleChange}
      >
      <option key="option-default" value=""></option>
      {props.items.map(v => (
        <option key={`option-${v.userId}`} value={v.userId}>
          {format(v)}
        </option>
      ))}
    </select>
  );
}
