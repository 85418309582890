import {
  Fragment,
  useEffect,
  useState,
} from "react";
import dayjs from "dayjs";
import {
  CssBaseline,
  Container,
} from '@material-ui/core';
import {
  makeStyles,
  createStyles,
  Theme
} from "@material-ui/core/styles";
import { MonthSelector, UserSelector } from '../components';
import {
  GenericTemplate,
} from "../components";
import {
  useAggregator,
  State as AggregatorState,
} from './AnalyticsPage/aggregator';
import {
  fetchAttendanceTypes,
} from '../service/api/attendances'
import {
  Matter,
  fetchMatters,
} from '../service/api/matters'
import {
  User,
  fetchUsers,
} from '../service/api/users'
import {
  AggregationHeaderColumn,
  AggregationRowData,
  fetchDailyMatterAggregation,
  isAggregationRowDataExist,
} from '../service/api/aggregation';

import './AnalyticsPage.css';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      paddingTop: "0px"
    },
    sunday: {
      color: theme.palette.secondary.main,
    },
    saturday: {
      color: theme.palette.primary.main,
    },
    day: {
      color: theme.palette.text.primary,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      // height: '20em'
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '0px solid #000',
      borderRadius: '1em',
      boxShadow: theme.shadows[5],
      // padding: theme.spacing(2, 4, 3),
      padding: '2em',
      minHeight: '50em',
      maxHeight: '80vh',
      minWidth: '70em',
      maxWidth: '70em',
      marginBlockStart: '0em'

    },
    dialogPaper: {
      minHeight: '800vh',
      maxHeight: '800vh',
    },
  })
);

function charsCSSSize(code: string, name: string): string {
  const padding = '1vw';
  return 'max(' + [code, name]
    .map(v => v.split(''))
    .map(v => {
      // ch単位とする文字数
      const ch = v.filter(c => c.match(/^[a-zA-Z0-9-_.]$/)).length;
      const em = v.length - ch;
      return `calc(${ch}ch + ${em}em) + ${padding}`;
    })
    .join(', ') + ')';
}

function mattersGridTemplateColumns(matters: Array<Matter>, header?: AggregationHeaderColumn) {
  if (header?.label !== 'matters') {
    return '';
  }
  return (header.headers || [])
    .map(header => matters.find(v => v.matterId === header.label) ?? {
      matterCode: 'ああああ',
      matterName: 'ああああ',
    })
    .map(v => charsCSSSize(v.matterCode, v.matterName))
    .join(' ');
}

interface HeaderRowProps {
  state: AggregatorState;
}

function HeaderRow(props: HeaderRowProps) {
  const forMatterCode = (label: string) => {
    if (label === 'total') {
      return '工数合計';
    }
    return props.state.matters.find(v => v.matterId === label)?.matterCode ?? '<nil>';
  };
  const forMatterName = (label: string) => {
    if (label === 'total') {
      return '（h）';
    }
    return props.state.matters.find(v => v.matterId === label)?.matterName ?? '<nil>';
  };

  const mattersHeader = props.state.$headers.find(v => v.label === 'matters');
  const gridTemplateColumns = mattersGridTemplateColumns(props.state.matters, mattersHeader);
  return (
    <>
      <div className="daily-report-header">日付</div>
      <div className="daily-report-header">勤怠種別</div>
      <div
        className="daily-repot-header works-container"
        style={{ gridTemplateColumns }}
        >
        {mattersHeader?.headers?.map((v, i) => (
          <div key={`work-header-${i}`} className="work-header">
            {forMatterCode(v.label)}<br />
            {forMatterName(v.label)}
          </div>
        ))}
      </div>
    </>
  );
}

interface BodyRowProps {
  state: AggregatorState;

  value: AggregationRowData;
}

function BodyRow(props: BodyRowProps) {
  const attendanceTypeName = () => {
    const value = props.value;
    if (!isAggregationRowDataExist(value)) {
      return '';
    }
    const attendanceType = props.state.attendanceTypes.find(v => v.attendanceTypeId === value.attendanceType);
    return attendanceType?.attendanceTypeName ?? '<nil>';
  };

  const forNumber = (value: AggregationRowData, label: string) => {
    if (!isAggregationRowDataExist(value)) {
      return '';
    }
    const n = value.matters[label];
    if (!n) {
      return '';
    }
    return n.toFixed(2);
  };

  const mattersHeader = props.state.$headers.find(v => v.label === 'matters');
  const gridTemplateColumns = mattersGridTemplateColumns(props.state.matters, mattersHeader);
  return (
    <>
      <div className="daily-report-content">{props.value.date}</div>
      <div className="daily-report-content">{attendanceTypeName()}</div>
      <div
        className="daily-report-content works-container"
        style={{ gridTemplateColumns }}
        >
        {mattersHeader?.headers?.map((header, i) => (
          <div key={`work-item-${i}`} className="work-item">
            {forNumber(props.value, header.label)}
          </div>
        ))}
      </div>
    </>
  );
}

export function AnalyticsPage() {
  const [year, setYear] = useState((new Date()).getFullYear());
  const [month, setMonth] = useState((new Date()).getMonth());
  const [user, setUser] = useState<User>();
  const [aggregatorState, aggregatorDispatch] = useAggregator();

  const handleMonthChange = (year: number, month: number) => {
    setYear(year);
    setMonth(month);
  };

  useEffect(() => {
    if (!user) {
      return aggregatorDispatch({ type: 'setValue', value: { headers: [], body: [] } });
    }
    // 集計結果取得
    (async () => {
      const d = dayjs().year(year).month(month);
      const v = await fetchDailyMatterAggregation(
        d.startOf('month').format("YYYY-MM-DD"),
        d.startOf('month').add(1, 'month').format("YYYY-MM-DD"),
        {
          userId: user?.userId,
        },
      );
      aggregatorDispatch({ type: 'setValue', value: v });
    })();
  }, [user, year, month, aggregatorDispatch]);

  const [users, setUsers] = useState<Array<User>>([]);
  useEffect(() => {
    // ユーザ一覧取得
    (async () => {
      const l = await fetchUsers();
      setUsers(l.items);
    })();
  }, []);

  useEffect(() => {
    // 案件一覧取得
    (async () => {
      const l = await fetchMatters();
      aggregatorDispatch({ type: 'setMatters', matters: l.items })
    })();
    // 勤務種別一覧取得
    (async () => {
      const l = await fetchAttendanceTypes();
      aggregatorDispatch({ type: 'setAttendanceTypes', attendanceTypes: l.items })
    })();
  }, [aggregatorDispatch]);

  const classes = useStyles();

  return (
    <GenericTemplate title="日報分析">
      <div className={classes.root}>
        <CssBaseline />
        <div style={{display: "flex", justifyContent: "center", position: "relative"}}>
          <MonthSelector
            year={year}
            month={month}
            onChange={handleMonthChange}
            />
          <UserSelector items={users} onChange={setUser} />
        </div>
        <div>
          日報入力状況
        </div>
        <Container className="daily-reports-container" maxWidth="xl" style={{padding: 0}}>
          <HeaderRow state={aggregatorState} />
          {aggregatorState.$body.map((row, i) => (
            <BodyRow key={`row-${i}`} state={aggregatorState} value={row} />
          ))}
        </Container>
        {/*
        <Container className="daily-reports-container" maxWidth="xl" style={{padding: 0}}>
          <>
            <div className="daily-report-header">日付</div>
            <div className="daily-report-header">出勤種別</div>
            <div className="daily-report-header works-container">
              <div className="work-header">
                工数合計<br />
                （h）
              </div>
              <div className="work-header">
                XXXXX<br />
                社内事務
              </div>
              <div className="work-header">
                XXXXX<br />
                営業
              </div>
              <div className="work-header">
                XXXXX<br />
                YYYY
              </div>
            </div>
          </>
          <>
            <div>&nbsp;</div>
            <div>&nbsp;</div>
            <div className="works-container">
              <div className="work-item">16.0</div>
              <div className="work-item">2.0</div>
              <div className="work-item">5.0</div>
              <div className="work-item">9.0</div>
            </div>
          </>
          <>
            <div>2023-06-01 (Sun)</div>
            <div>休日</div>
            <div className="works-container">
              <div className="work-item">8.0</div>
              <div className="work-item">1.0</div>
              <div className="work-item">2.5</div>
              <div className="work-item">4.5</div>
            </div>
          </>
          <>
            <div>2023-06-02 (Mon)</div>
            <div>出勤</div>
            <div className="works-container">
              <div className="work-item">8.0</div>
              <div className="work-item">1.0</div>
              <div className="work-item">2.5</div>
              <div className="work-item">4.5</div>
            </div>
          </>
        </Container>
        */}
      </div>
    </GenericTemplate>
  );
};

export default AnalyticsPage;
