import { doApi } from "./index";

export interface WorkItem {
  workItemId: string;
  workItemCode: string;
  workItemName: string;
}

export interface WorkItemList {
  items: WorkItem[]
}

// 作業項目一覧取得
export const fetchWorkItems = async (): Promise<WorkItemList> => {
  return doApi({
    method: 'GET',
    path: '/workItems',
  });
}
