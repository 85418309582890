import {
  ReactChild,
} from 'react';


interface PageMainProps {
  content: ReactChild;
}

export function PageMain(props: PageMainProps) {
  return (
    <article className="PageMain">
      {props.content}
    </article>
  );
}
