import { doApi } from "./index";

export interface Matter {
  matterId: string;
  matterCode: string;
  matterName: string;
  salesMonth: string;
  statusCode: string;
  hidden: boolean;
}

export interface MatterList {
  items: Matter[];
}

// 案件一覧取得
export async function fetchMatters(opts?: {
  includeHidden?: boolean;
}): Promise<MatterList> {
  opts = opts || {};
  return doApi({
    method: 'GET',
    path: '/matters',
    data: {
      includeHidden: !!opts.includeHidden,
    },
  });
}

export interface SyncMattersRequest {
  accessToken: string;
}

// 案件一覧同期
export const syncMatters = async (data: SyncMattersRequest): Promise<void> => {
  return doApi({
    method: 'POST',
    path: '/matters/sync',
    data,
  });
}

export async function updateMatter(data: Matter): Promise<Matter> {
  return doApi({
    method: 'PUT',
    path: `/matters/${data.matterId}`,
    data,
  })
}
