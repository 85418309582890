import { doApi } from "./index";

export interface DailyMatterAggregation {
  headers: Array<AggregationHeaderColumn>;

  body: Array<AggregationRowData>;
}

export interface AggregationHeaderColumn {
  label: 'date' | 'attendanceType' | 'matters';

  headers?: Array<AggregationHeaderColumn>;
}

interface AggregationRowDataEmpty {
  date: string;
};

interface AggregationRowDataExist {
  date: string;

  attendanceType: string;

  matters: { [k: string]: number };
};

export function isAggregationRowData(v: unknown): v is AggregationRowData {
  return !!v && typeof v === 'object' && 'date' in v;
}

export function isAggregationRowDataExist(v: unknown): v is AggregationRowDataExist {
  return isAggregationRowData(v) && 'attendanceType' in v;
}

export type AggregationRowData = AggregationRowDataEmpty | AggregationRowDataExist;

export async function fetchDailyMatterAggregation(from: string, to: string, opts?: { userId?: string }): Promise<DailyMatterAggregation> {
  opts = opts || {};
  opts.userId = opts.userId ?? '';

  return doApi({
    method: 'GET',
    path: '/aggregations/daily-matter',
    data: {
      from,
      to,
      user: opts.userId,
    },
  });
}
