import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import { AuthProvider } from "./contexts/AuthProvider";
import {
  CalendarPage,
  AnalyticsPage,
  LoginPage
} from "./pages";
import {
  MattersPage,
} from "./pages/admin";
import {
  PrivateRoute,
} from "./contexts/AuthRoute";
import { UnauthorizedError, } from './service/api';
import { getSession, } from './service/api/auth';
import {
  ToastContainer,
  toast
} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App: React.FC = () => {
  const [blurAt, setBlurAt] = React.useState(new Date().getTime());
  React.useEffect(() => {
    const handler = (evt: ErrorEvent | PromiseRejectionEvent) => {
      let err: any;
      if (evt instanceof ErrorEvent) {
        err = evt.error;
      } else if (evt instanceof PromiseRejectionEvent) {
        err = evt.reason;
      } else {
        err = String(evt);
      }
      toast(`unknown error: ${err}`);

      if (err instanceof UnauthorizedError) {
        // リロードすれば再度セッションが払い出されるか、ログイン画面に飛ばされる
        toast('セッションが切れたため、このメッセージが消えると画面を自動で再読み込みします。', {
          onClose: () => {
            window.location.reload();
          },
        });
      }
    };
    window.addEventListener('unhandledrejection', handler);
    window.addEventListener('error', handler);
  }, []);
  React.useEffect(() => {
    const blurHandler = () => {
      setBlurAt(new Date().getTime());
    };
    const focusHandler = () => {
      const leaveSeconds = Math.round((new Date().getTime() - blurAt) / 1000);

      console.log('leaveSeconds', leaveSeconds);
      // if (leaveSeconds <= 30 * 60) {
      if (leaveSeconds <= 3) {
        return;
      }
      // 単にリクエスト飛ばすだけで良い
      // セッション切れていたら、401が発生してリロードがかかるはず
      getSession();
    };
    // 長時間画面を放置して、再度画面に戻ってきたときにセッション情報を確認する
    // 入力してからAPIを呼んで、セッション切れでリロードになると悲しい
    window.addEventListener('blur', blurHandler);
    window.addEventListener('focus', focusHandler);
    return () => {
      window.removeEventListener('blur', blurHandler);
      window.removeEventListener('focus', focusHandler);
    };
  }, [blurAt]);
  return (
    <>
      <AuthProvider>
        <Router>
          <Switch>
            <PrivateRoute path="/analytics" component={AnalyticsPage} exact />
            <PrivateRoute path="/calendars" component={CalendarPage} exact />
            <PrivateRoute path="/admin/matters" component={MattersPage} exact />
            <Route path="/" component={LoginPage} />
          </Switch>
        </Router>
      </AuthProvider>
      <ToastContainer
        position="bottom-right"
      />
    </>
  );
};

export default App;
