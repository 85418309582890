import * as React from 'react';
import styles from './CalendarHeaderCell.module.css';

interface CalendarHeaderCellProps {
  children: React.ReactNode;
}

export const CalendarHeaderCell: React.FC<CalendarHeaderCellProps>= ({ children }) => {
  return (
    <div className={styles.cell}>
      <div className={styles['inner-text']}>
        {children}
      </div>
    </div>
  )
}

export default CalendarHeaderCell;
