import {
  useContext,
} from 'react';
import {
  AuthContext,
} from '../../contexts/AuthProvider';
import {
  Menu as MenuIcon,
} from "@material-ui/icons";


export interface HeaderProps {
  title: string;

  onMenuButtonClick?: () => void;
}

export function Header(props: HeaderProps) {
  const { authState, dispatch: authDispatch } = useContext(AuthContext);
  const onMenuButtonClick = () => {
    !!props.onMenuButtonClick && props.onMenuButtonClick();
  };
  const handleSignOut = () => {
    authDispatch({ type: "logout" });
  };

  return (
    <header className="Header">
      <div className="container">
        <div className="icon-panel" onClick={onMenuButtonClick}>
          <MenuIcon />
        </div>
        <div className="title-panel">
          {props.title}
        </div>

        <div className="session-panel">
          ようこそ、 {authState.user?.userName}様
          <button className="signout-button" onClick={handleSignOut}>
            ログアウト
          </button>
        </div>
      </div>
    </header>
  );
}
