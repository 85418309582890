import { doApi } from "./index";

// セッションを取得
export const getSession = async (): Promise<void> => {
  return doApi({
    method: 'GET',
    path: '/session',
  });
}

// セッションを登録
export const postSession = async (idToken: string) => {
  return doApi({
    method: 'POST',
    path: '/session',
    headers: {
      'Authorization': `Bearer ${idToken}`,
    },
  });
}

// セッションを削除
export const deleteSession = async () => {
  return doApi({
    method: 'DELETE',
    path: '/session',
  });
}
