import React, {
  createContext,
  useState,
  useEffect,
  useReducer,
} from "react";
import {
  postSession,
  deleteSession,
} from "../service/api/auth"
import {
  User,
  fetchUser
} from "../service/api/users"
import HashLoader from 'react-spinners/HashLoader'
import firebase from 'firebase/compat/app';

interface ActionLogin {
  type: 'login';
}

interface ActionLogout {
  type: 'logout';
}

interface ActionLoginDone {
  type: 'loginDone';
  user: User;
}

interface ActionLogoutDone {
  type: 'logoutDone';
}

type Action = ActionLogin
            | ActionLogout
            | ActionLoginDone
            | ActionLogoutDone;

export interface AuthState {
  user?: User;
  isAuth: boolean;
}

type AuthContextType = {
  authState: AuthState;
  dispatch: React.Dispatch<Action>
}

export const AuthContext = createContext<AuthContextType>({
  authState: {} as AuthState,
  dispatch: {} as React.Dispatch<Action>
})

const LoadingIndicator = () => {
  return (
    <div style={{margin: "50vh auto 0", transform: `translateY(-50%)`, textAlign: "center"}}>
      <HashLoader size={60} color={"#36d7b7"}/>
    </div>
  )
};

const authReducer = (state: AuthState, action: Action): AuthState => {
  const doLogin = async () => {
    // SESSIONベースにするため、ブラウザ側でキャッシュしない
    await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.NONE);
    const provider = new firebase.auth.GoogleAuthProvider();
    await firebase.auth().signInWithRedirect(provider);
  };

  switch (action.type) {
    case 'login':
      doLogin();
      return state;
    case 'logout':
      firebase.auth().signOut();
      return state;
    case 'logoutDone':
      return {
        isAuth: false,
      };
    case 'loginDone':
      return {
        user: action.user,
        isAuth: true,
      };
    default:
      return {...state};
  }
};

export const AuthProvider = (props: any) => {
  const [loading, setLoading] = useState(true);
  const [authState, dispatch] = useReducer(authReducer, {
    isAuth: false,
  });

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (fbUser) => {
      console.log('onAuthStateChanged', fbUser);
      if (!fbUser) {
        await firebase.auth().signOut();
        await deleteSession();
        dispatch({ type: 'logoutDone' });
        setLoading(false);
        return;
      }

      const idToken = await fbUser.getIdToken();
      await postSession(idToken);

      const user = await fetchUser();
      dispatch({
        type: 'loginDone',
        user,
      });
      setLoading(false);
    });
  }, []);

  return (
    loading
      ? <LoadingIndicator />
      : <AuthContext.Provider value={{authState, dispatch}}>
          {props.children}
        </AuthContext.Provider>
  )
};
