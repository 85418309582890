import React from "react";
import dayjs from "dayjs";

import { CalendarCell, CalendarHeaderCell } from ".";
import { CellType } from "./CalendarCell"
import { DailyReport } from "../service/api/dailyreports";

import styles from "./Calendar.module.css";

interface CalendarDate {
  year: number;
  month: number;
  date: number
}
interface CalendarProps {
  date: CalendarDate;
  dailyReportList: DailyReport[];
  onclick: (year:number, month:number, date: number) => void;
}

//TODO: 関数化？
const weekArray = ['日','月','火','水','木','金','土'];

// カレンダー全体（Header + Body）を構成する。
export const Calendar: React.FC<CalendarProps> = (props: CalendarProps) => {
  // 月初の曜日
  const currentDate = dayjs().year(props.date.year).month(props.date.month - 1).date(props.date.date)
  const startDate = currentDate.startOf('month')
  const cellArray = [...Array(6 * 7)].map((_, i) => i);

  // カレンダーの開始日
  let dateDayjs = startDate.subtract(startDate.day(), 'day');
  return (
    <>
      <div className={styles.calendar} style={{height: '2em'}}>
        {weekArray.map(w => <CalendarHeaderCell key={w}>{w}</CalendarHeaderCell>)}
      </div>

      <div className={styles.calendar} style={{marginTop: '2px'}}>
        {cellArray.map(_ => {
          const tmpDayjs = dateDayjs.clone();
          const weekNumber = tmpDayjs.day();
          const dailyReport = props.dailyReportList?.find(v => v.date === tmpDayjs.format('YYYY-MM-DD'));
          const cellType = (dailyReport?: DailyReport): CellType => {
            if (dailyReport) {
              return 'saved';
            } else if (weekNumber === 0) {
              return 'sunday';
            } else if (weekNumber === 6) {
              return 'saturday';
            } else if (tmpDayjs.month() !== props.date.month - 1) {
              return 'outside';
            }
            return 'default'
          }

          const cell = (): JSX.Element =>
            <CalendarCell
              key={tmpDayjs.format('YYYY-MM-DD')}
              weekNumber={weekNumber}
              cellType={cellType(dailyReport)}
              text={String(tmpDayjs.date())}
              dailyReport={dailyReport}
              onclick={() => {props.onclick(tmpDayjs.year(), tmpDayjs.month() + 1, tmpDayjs.date())}}
            />;
          dateDayjs = dateDayjs.add(1, 'day');
          return cell();
        })}
      </div>
    </>
  )
}

export default Calendar;
