import {
  useReducer,
} from 'react';
import {
  Matter,
} from '../../service/api/matters';
import {
  AttendanceType,
} from '../../service/api/attendances';
import {
  DailyMatterAggregation,
  AggregationHeaderColumn,
  AggregationRowData,
} from '../../service/api/aggregation';


export type State = {
  matters: Array<Matter>;

  attendanceTypes: Array<AttendanceType>;

  value: DailyMatterAggregation;

  $headers: Array<AggregationHeaderColumn>;

  $body: Array<AggregationRowData>;
};

interface SetValueAction {
  type: 'setValue';

  value: DailyMatterAggregation;
}

interface SetMattersAction {
  type: 'setMatters';

  matters: Array<Matter>;
}

interface SetAttendanceTypesAction {
  type: 'setAttendanceTypes';

  attendanceTypes: Array<AttendanceType>;
}

type Action
  = SetValueAction
  | SetMattersAction
  | SetAttendanceTypesAction;

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'setValue':
      return {
        ...state,
        value: action.value,
        $headers: action.value.headers,
        $body: action.value.body,
      };
    case 'setMatters':
      return {
        ...state,
        matters: action.matters,
      };
    case 'setAttendanceTypes':
      return {
        ...state,
        attendanceTypes: action.attendanceTypes,
      };
    default:
      console.warn('unknown action', action);
      return state;
  }
}

export function useAggregator() {
  return useReducer(reducer, {
    matters: [],
    attendanceTypes: [],
    value: {
      headers: [],
      body: [],
    },
    $headers: [],
    $body: [],
  });
}
