export class BadRequestError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = new.target.name;
  }
}
export class UnauthorizedError extends Error {
  constructor(message?: string) {
    super(message);
    this.name = new.target.name;
  }
}

export async function doApi(opts: {
  method: string;
  path: string;
  headers?: { [name: string]: string };
  data?: any;
}): Promise<any> {
  const url = window.App.config.apiUrl + '/api' + opts.path;
  const reqInit: RequestInit = {
    method: opts.method,
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      ...(opts.headers || {}),
    },
  };
  const qp = new URLSearchParams();
  if (opts.method === 'GET') {
    Object.keys(opts.data || {}).forEach((k) => {
      qp.append(k, opts.data[k]);
    });
  } else {
    reqInit.body = JSON.stringify(opts.data);
  }
  const res = await fetch(url + '?' + qp.toString(), reqInit);
  switch (res.status) {
    case 200: // OK
      return res.json();
    case 201: // Created
      return res.json();
    case 204: // NoContent
      return;
    case 400: // BadRequest
      throw new BadRequestError(`${res.statusText}: ${await res.text()}`);
    case 401: // fallthrough
    case 403: // Unauthorized
      throw new UnauthorizedError(`${res.statusText}: ${await res.text()}`);
    default:
      throw new Error(`${res.statusText}: ${await res.text()}`);
  }
}
