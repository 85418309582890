import dayjs from "dayjs";
import { Typography } from "@material-ui/core";

export const Copyright = () => {
  // 開始年
  const startYear = 2021
  // 現在年
  const currentYear = dayjs().year();
  const year = currentYear === startYear ? currentYear : `${currentYear}-${startYear}`;
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {`Copyright © ${year} Centillion System inc. All Rights Reserved.`}
    </Typography>
  );
};

export default Copyright;
