import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      width: 400,
      height: 200,
    },
  })
);

interface MessageDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export const MessageDialog: React.FC<MessageDialogProps> = (props: MessageDialogProps): JSX.Element => {
  const classes = useStyles();

  const onConfirm = (): void => {
    props.setOpen(false)
    props.onConfirm();
  };

  const onCancel = (): void => {
    props.setOpen(false)
    props.onCancel();
  };

  return (
    <Dialog
      open={props.open}
      onClose={onCancel}
      aria-labelledby={"form-dialog-title"}
    >
      <DialogTitle id={"form-dialog-title"}>
        <b>{props.title}</b>
      </DialogTitle>
      <DialogContent className={classes.dialog}>
        <Typography>
          {props.message}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} color="primary">
          はい
        </Button>
        <Button onClick={onCancel} color="secondary">
          いいえ
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default MessageDialog;
