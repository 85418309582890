import { useContext, VFC } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { AuthContext } from './AuthProvider';

export const PrivateRoute: VFC<RouteProps> = ({ component: Component, ...rest }) => {
  const { authState } = useContext(AuthContext);
  return (
    authState.isAuth
      ? <Route component={Component} {...rest} />
      : <Redirect to={{pathname:"/"}} />
  );
};
