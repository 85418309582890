import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import {
  Modal,
  Button,
  Backdrop,
  Fade,
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  PropTypes
} from '@material-ui/core';
import {
  AddCircle,
  Delete,
} from '@material-ui/icons';
import {
  makeStyles,
  createStyles,
  Theme,
} from "@material-ui/core/styles";

import styles from './DailyReportModal.module.css';
import { ButtonArea } from '.';
import {
  DailyReport,
  ActualWork,
  deleteDailyReport,
  putDailyReport,
  postDailyReport,
} from '../service/api/dailyreports'
import { AttendanceType } from '../service/api/attendances'
import { Matter } from '../service/api/matters'
import { WorkItem } from '../service/api/workitems'
import { AuthContext } from "../contexts/AuthProvider";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '0px solid #000',
      borderRadius: '1em',
      boxShadow: theme.shadows[5],
      padding: '1em 2em',
      minHeight: '90vh',
      maxHeight: '90vh',
      minWidth: '80vw',
      maxWidth: '80vw',
      marginBlockStart: '0em',
      // overflowY: 'auto'
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      // paddingLeft: '10px',
      width: '25ch',
    },
    root: {
      width: '100%',
    },
    table: {
      minWidth: 750,
    },
    // title: {
    //   flex: '1 1 100%',
    // },
    container: {
      maxHeight: '12.8em',
    },
    resize: {
      fontSize: '0.8em',
      paddingLeft: '0px',
      paddingBottom: '4px'
    },
    resize2: {
      fontSize: '0.8em',
      paddingBottom: '4px'
    },
    stylesLabel: {
      color : 'green'
    },
  }),
);


interface DailyReportModalProps {
  open: boolean;
  handleClose: () => void;
  onCreate: (v: DailyReport) => void;
  onUpdate: (v: DailyReport) => void;
  onDelete: (v: DailyReport) => void;
  date: {
    year: number;
    month: number;
    date: number;
  };
  dailyReport: DailyReport;
  dailyReportList: DailyReport[];
  matters: Matter[];
  workItems: WorkItem[];
  attendanceTypes: AttendanceType[];
}

export const DailyReportModal: React.FC<DailyReportModalProps> = (props: DailyReportModalProps): JSX.Element => {
  const classes = useStyles();
  const { user } = useContext(AuthContext).authState;
  const startTimeRef = useRef<HTMLTextAreaElement>(null);
  const endTimeRef = useRef<HTMLTextAreaElement>(null);
  const breakTimeRef = useRef<HTMLTextAreaElement>(null);
  const noteRef = useRef<HTMLTextAreaElement>(null);

  const [startTimeError, setStartTimeError] = useState<boolean>(false);
  const [endTimeError, setEndTimeError] = useState<boolean>(false);
  const [breakTimeError, setBreakTimeError] = useState<boolean>(false);
  const [attendanceTypeId, setAttendanceTypeId] = useState(props.dailyReport.attendanceTypeId);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAttendanceTypeId(event.target.value);
  };

  const { date } = props;
  interface ReducerAction {
    type: string;
    actualWork?: ActualWork;
    id?: string;
  }

  const reducer = (state: ActualWork[], action: ReducerAction): ActualWork[] => {
    switch (action.type) {
      case 'push':
        // 毎回変化する値を生成するため、reducer内でID採番
        return [
          ...state,
          {
            ...action.actualWork!,
            actualWorkId: uuidv4(),
          },
        ];
      case 'remove':
        return state.filter(v => v.actualWorkId !== action.id);
      case 'set':
        return state.map(v => v.actualWorkId === action.id ? action.actualWork! : v);
      case 'clear':
        return [];
      default:
        return state;
    }
  }

  const [actualWorks, dispatch] = useReducer(reducer, []);

  useEffect(() => {
    setEndTimeError(false);
    setBreakTimeError(false);
    setAttendanceTypeId(props.dailyReport.attendanceTypeId ?? props.attendanceTypes.find(v => v.attendanceTypeCode === '1')?.attendanceTypeId);
  }, [props.dailyReport, props.attendanceTypes]);

  // 作業項目追加
  const addActualWork = useCallback(() => {
    dispatch({
      type: 'push',
      actualWork: {
        actualWorkId: '',
        matterId: '',
        workItemId: '',
        workTime: '',
        note: ''
      },
    })
  }, []);

  // 作業項目除去
  const removeActualWork = (id: string) => {
    dispatch({
      type: 'remove',
      id: id
    });
  }

  useEffect(() => {
    dispatch({type: 'clear'});
    if (props.dailyReport.actualWorks) {
      props.dailyReport.actualWorks.forEach(aw => {
        dispatch({
          type: 'push',
          actualWork: {
            ...aw,
            actualWorkId: uuidv4()
          }
        });
      })
    } else {
      addActualWork();
    }
  }, [props.dailyReport.actualWorks, addActualWork]);

  // 削除
  const deleteHandler = () => {
    if (props.dailyReport.dailyReportId) {
      deleteDailyReport(props.dailyReport.dailyReportId!)
        .then(data => {
          props.handleClose();
          props.onDelete(props.dailyReport);
        })
        .catch(error => console.log(error))
    }
  }

  // const validate = (dailyReport: DailyReport): boolean => {
  //   const attendanceTypeCode = props.attendanceTypes.find(v => v.attendanceTypeId === dailyReport.attendanceTypeId)?.attendanceTypeCode;
  //   if (attendanceTypeCode === "1") {
  //     if (!dailyReport.startTime) {
  //       // setErrorMessage('開始時刻を入力してください。')
  //     }
  //     if (!dailyReport.endTime) {
  //     }
  //     if (!dailyReport.breakTime) {
  //     }
  //     if (dailyReport.actualWorks.length === 0) {
  //     }
  //   }
  //   return false;
  // }

  // 保存ボタン
  const saveButton = {
    label: '保存',
    color: 'primary' as PropTypes.Color,
    variant: 'contained',
    clickHandler: () => {
      const currentDate = dayjs().year(date.year).month(date.month - 1).date(date.date);

      const dailyReport = {
        userId: user!.userId,
        date: currentDate.format("YYYY-MM-DD"),
        attendanceTypeId: attendanceTypeId,
        startTime: startTimeRef.current?.value,
        endTime: endTimeRef.current?.value,
        breakTime: breakTimeRef.current?.value,
        status: 'new',
        actualWorks: actualWorks.filter(v => v.matterId.length > 0),
        note: noteRef.current?.value
      } as DailyReport;

      // if (!validate(dailyReport)) {
      //   alert('validate error');
      //   return;
      // }

      if (props.dailyReport.dailyReportId) {
        // 更新時
        putDailyReport({...dailyReport, dailyReportId: props.dailyReport.dailyReportId})
          .then(data => {
            props.handleClose();
            props.onUpdate(data);
          })
          .catch(err => console.log(err));
      } else {
        // 作成時
        postDailyReport(dailyReport)
          .then(data => {
            props.handleClose();
            props.onCreate(data);
          })
          .catch(err => console.log(err));
      }
    },
  }

  const cancelButton = {
    label: 'キャンセル',
    color: 'primary' as PropTypes.Color,
    clickHandler: () => props.handleClose()
  }

  const inputProps = {
    classes: {
      input: classes.resize,
    },
  }

  const inputProps2 = {
    classes: {
      input: classes.resize2,
    },
  }

  const format = (time?: string): string => {
    if (!time) {
      return '';
    }
    if (time.match(/[^0-9:]/) || time.match(/[0-9]{1,2}:[0-9]{2}/)) {
      return time;
    }

    if (time.match(/:/)) {
      time = time.replaceAll(/:/, '');
    }
    time = Number(time).toString();
    time = time.padStart(4, '0');
    time = `${time.slice(0, 2)}:${time.slice(2,4)}`;
    return time.replace(/^0+([0-9]+:[0-9]+)/, '$1');
  }

  const validateWorkTime = (text: string) => {
    return text.match(/[0-9]{1,2}:[0-9]{2}/) ? false : true;
  }

  return (
    <Modal
      className={classes.modal}
      open={props.open}
      onClose={() => props.handleClose()}
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 100 }}
      closeAfterTransition
    >
    <Fade in={props.open}>
      <div className={classes.paper}>
        <Typography variant="h6" className={styles['flex']}>
          <div>{`${date.month}月 ${date.date}日`}</div>
          <Button color="secondary" style={{ marginLeft: 'auto' }} onClick={deleteHandler}>削除</Button>
        </Typography>
        <div className={styles.content}>
          <Typography variant="subtitle1" style={{ fontWeight: "bold", marginTop: "1em" }}>
            勤怠情報
          </Typography>
          <div>
            <TextField
              select
              id="attendance-type"
              style={{ margin: 8, width: '10em' }}
              label="勤務種別"
              value={attendanceTypeId}
              defaultValue={props.dailyReport.attendanceTypeId ?? props.attendanceTypes.find(v => v.attendanceTypeCode === '1')?.attendanceTypeId}
              onChange={handleChange}
            >
              {props.attendanceTypes.sort((a, b) => a.attendanceTypeCode > b.attendanceTypeCode ? 1 : -1).map((attendanceType: AttendanceType) => (
                <MenuItem key={attendanceType.attendanceTypeId} value={attendanceType.attendanceTypeId}>
                  {attendanceType.attendanceTypeName}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div>
            <TextField
              id="startTime"
              label="出社時刻"
              className={styles['input-time']}
              placeholder="10:00"
              error={startTimeError}
              helperText={startTimeRef?.current?.validationMessage}
              margin="normal"
              onBlur={() => {
                startTimeRef.current!.value = format(startTimeRef.current?.value)
                setStartTimeError(!startTimeRef.current!.validity.valid);
              }}
              defaultValue={props.dailyReport.startTime ?? '10:00'}
              inputRef={startTimeRef}
              inputProps={{ pattern: '[1-9]?[0-9]:[0-9]{2}' }}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              id="endTime"
              label="退社時刻"
              className={styles['input-time']}
              placeholder="19:00"
              error={endTimeError}
              helperText={endTimeRef?.current?.validationMessage}
              inputRef={endTimeRef}
              inputProps={{ pattern: '[1-9]?[0-9]:[0-9]{2}' }}
              margin="normal"
              onBlur={() => {
                endTimeRef.current!.value = format(endTimeRef.current?.value)
                setEndTimeError(!endTimeRef.current!.validity.valid);
              }}
              defaultValue={props.dailyReport.endTime ?? '19:00'}
              InputLabelProps={{ shrink: true }}
            />
            <TextField
              id="breakTime"
              label="休憩時間"
              className={styles['input-time']}
              placeholder="1:00"
              error={breakTimeError}
              helperText={breakTimeRef?.current?.validationMessage}
              margin="normal"
              inputRef={breakTimeRef}
              inputProps={{ pattern: '[1-9]?[0-9]:[0-9]{2}' }}
              onBlur={() => {
                breakTimeRef.current!.value = format(breakTimeRef.current?.value)
                setBreakTimeError(!breakTimeRef.current!.validity.valid);
              }}
              defaultValue={props.dailyReport.breakTime ?? '1:00'}
              InputLabelProps={{ shrink: true }}
            />
          </div>
          <div>
            <div style={{minHeight: '28vh', maxHeight: '28vh'}}>
            <Typography
              variant="subtitle1"
              id="tableTitle"
              style={{ fontWeight: "bold", marginTop: "1em" }}
            >
              稼働実績
            </Typography>
              <TableContainer className={classes.container}>
                <Table size={'small'} stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className={`${styles['header-tableCell']} ${styles.matter}`}>案件</TableCell>
                      <TableCell className={`${styles['header-tableCell']} ${styles.workItem}`}>作業項目</TableCell>
                      <TableCell className={`${styles['header-tableCell']} ${styles.workTime}`}>作業時間</TableCell>
                      <TableCell className={`${styles['header-tableCell']} ${styles.note}`}>備考</TableCell>
                      <TableCell className={`${styles['header-tableCell']} ${styles.delete}`}/>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {actualWorks.map(w =>
                      <TableRow hover role="checkbox" tabIndex={-1} key={w.actualWorkId}>
                        <TableCell
                          key={`${w.actualWorkId}-matter`}
                          align="left"
                          className={styles['body-tableCell']}
                        >
                          <TextField
                            id={`${w.actualWorkId}-matter`}
                            select
                            onChange={e => dispatch({type: 'set', id: w.actualWorkId, actualWork: {...w, matterId: e.target.value}})}
                            style={{ margin: 4 , width: '20em'}}
                            value={w.matterId}
                            error={w.matterId === ''}
                            InputLabelProps={{
                              classes: {
                                root: classes.stylesLabel
                              },
                            }}
                            InputProps={inputProps}
                            size={'small'}
                          >
                            {props.matters?.sort((a, b) => a.matterName > b.matterName ? 1 : -1).map((matter: Matter) =>
                              <MenuItem key={`${w.actualWorkId}-${matter.matterId}`} value={matter.matterId}>
                                [{matter.matterCode}] {matter.matterName}
                              </MenuItem>
                            )}
                          </TextField>
                        </TableCell>
                        <TableCell key={`${w.actualWorkId}-workItem`} align="left" className={styles['body-tableCell']}>
                          <TextField
                            id={`${w.actualWorkId}-workItem`}
                            select
                            style={{ margin: 4 }}
                            onChange={(e) => dispatch({type: 'set', id: w.actualWorkId, actualWork: {...w, workItemId: e.target.value}})}
                            error={w.workItemId === ''}
                            className={classes.textField}
                            value={w.workItemId}
                            InputProps={inputProps}
                            size={'small'}
                            fullWidth
                          >
                            {props.workItems?.map((workItem: WorkItem) =>
                              <MenuItem key={`${w.actualWorkId}-${workItem.workItemId}`} value={workItem.workItemId}>
                                {workItem.workItemName}
                              </MenuItem>
                            )}
                          </TextField>
                        </TableCell>
                        <TableCell key={`${w.actualWorkId}-workTime`} align="left" style={{backgroundColor: '#fcfcfc', paddingTop: 0, paddingBottom: 0}}>
                          <TextField
                            id={`${w.actualWorkId}-workTime`}
                            error={validateWorkTime(w.workTime)}
                            style={{ margin: 4 }}
                            placeholder="1:00"
                            onChange={e => dispatch({type: 'set', id: w.actualWorkId, actualWork: {...w, workTime: e.target.value}})}
                            onBlur={e => {
                              dispatch({ type: 'set', id: w.actualWorkId, actualWork: {...w, workTime: format(e.target.value)} })
                              e.target.value = format(e.target.value);
                            }}
                            defaultValue={w.workTime}
                            InputLabelProps={{ shrink: true }}
                            InputProps={inputProps2}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell key={`${w.actualWorkId}-note`} align="left" className={styles['body-tableCell']}>
                          <TextField
                            id={`${w.actualWorkId}-note`}
                            style={{ padding: 0, margin: 4 }}
                            placeholder="備考"
                            onChange={(e) => dispatch({ type: 'set', id: w.actualWorkId, actualWork: {...w, note: e.target.value} })}
                            defaultValue={w.note}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            InputProps={inputProps2}
                            fullWidth
                          />
                        </TableCell>
                        <TableCell key={`${w.matterId}-${w.workItemId}-delete`} align="left" className={styles['body-tableCell']}>
                          <IconButton key={uuidv4()} onClick={() => removeActualWork(w.actualWorkId)} style={{ padding: 0 }}>
                            <Delete color={"secondary"} fontSize={"small"}></Delete>
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <IconButton onClick={addActualWork} style={{ padding: 0, marginTop: '0.2em', marginLeft: '0.7em' }}>
                <AddCircle color={"primary"} fontSize={"small"}></AddCircle>
              </IconButton>
            </div>
            <Typography
              // className={classes.title}
              variant="subtitle1"
              id="tableTitle"
              component="div"
              style={{ fontWeight: "bold", marginTop: "1em" }}
            >
              備考
            </Typography>
            <TextField
              id="note"
              rows={5}
              placeholder="備考"
              variant="outlined"
              margin="dense"
              inputRef={noteRef}
              defaultValue={props.dailyReport.note}
              fullWidth
              multiline
            />
          </div>
        </div>
        <ButtonArea buttons={[saveButton, cancelButton]}/>
        </div>
      </Fade>
    </Modal>
  );
};

export default DailyReportModal;
