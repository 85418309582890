import { doApi } from "./index";

export interface DailyReport {
  dailyReportId?: string;
  userId: string;
  attendanceTypeId: string;
  date: string;
  startTime: string;
  endTime: string;
  breakTime: string;
  status: string;
  note: String;
  actualWorks: ActualWork[];
}

export interface ActualWork {
  actualWorkId: string;
  matterId: string;
  workItemId: string;
  workTime: string;
  note: string;
}

// 日報登録
export const postDailyReport = async (dailyReport: DailyReport): Promise<DailyReport> => {
  return doApi({
    method: 'POST',
    path: '/dailyreports',
    data: dailyReport,
  });
}

// 日報一覧取得
export const listDailyReports = async (from: string, to: string, opts?: { userId?: string }): Promise<{items: DailyReport[]}> => {
  opts = opts || {};
  opts.userId = opts.userId ?? '';

  return doApi({
    method: 'GET',
    path: '/dailyreports',
    data: {
      from,
      to,
      user: opts.userId,
    },
  });
}

// 日報取得
export const getDailyReport = async (dailyReportId: string): Promise<DailyReport> => {
  return doApi({
    method: 'GET',
    path: `/dailyreports/${dailyReportId}`,
  });
}

// 日報更新
export const putDailyReport = async (dailyReport: DailyReport): Promise<DailyReport> => {
  return doApi({
    method: 'PUT',
    path: `/dailyreports/${dailyReport.dailyReportId}`,
    data: dailyReport,
  });
}

// 日報削除
export const deleteDailyReport = async (dailyReportId: string): Promise<void> => {
  return doApi({
    method: 'DELETE',
    path: `/dailyreports/${dailyReportId}`,
  });
}
