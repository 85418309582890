import { doApi } from "./index";

export interface AttendanceType {
  attendanceTypeId: string;
  attendanceTypeCode: string;
  attendanceTypeName: string;
}

export interface AttendanceTypeList {
  items: AttendanceType[];
}

// 勤怠種別一覧取得
export const fetchAttendanceTypes = async (): Promise<AttendanceTypeList> => {
  return doApi({
    method: 'GET',
    path: '/attendanceTypes',
  });
}
