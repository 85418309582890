import React, {
  useContext,
  useState,
} from "react";
import { Redirect } from 'react-router-dom';
import clsx from "clsx";
import * as colors from "@material-ui/core/colors";
import { makeStyles, createStyles, Theme, createTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import { AppBar, Toolbar, CssBaseline, Button, Typography, Container } from "@material-ui/core";
import { AuthContext } from "../contexts/AuthProvider";

const theme = createTheme({
  typography: {
    fontFamily: [
      "Noto Sans JP",
      "Lato",
      "游ゴシック Medium",
      "游ゴシック体",
      "Yu Gothic Medium",
      "YuGothic",
      "ヒラギノ角ゴ ProN",
      "Hiragino Kaku Gothic ProN",
      "メイリオ",
      "Meiryo",
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: { main: colors.blue[800] }, // テーマの色
  },
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    toolbar: {
      paddingRight: 24,
    },
    toolbarIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: "0 8px",
      ...theme.mixins.toolbar,
    },
    appBar: {
      backgroundColor: "black",
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: "none",
    },
    title: {
      flexGrow: 1,
    },
    pageTitle: {
      marginBottom: theme.spacing(1),
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      height: "100vh",
      overflow: "auto",
    },
    container: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    paper: {
      padding: theme.spacing(2),
      display: "flex",
      overflow: "auto",
      flexDirection: "column",
    },
    link: {
      textDecoration: "none",
      color: theme.palette.text.secondary,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  })
);

export const LoginPage: React.VFC = () => {
  const authContext = useContext(AuthContext);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const handleLogin = () => {
    setButtonDisabled(true);
    authContext.dispatch({type: 'login'});
  };
  const classes = useStyles();

  // ログイン済なら、デフォルトページへリダイレクト
  if (!!authContext.authState.isAuth) {
    return (
      <Redirect to={{pathname: '/calendars'}} />
    );
  }
  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="absolute"
          className={clsx(classes.appBar)}
        >
          <Toolbar className={classes.toolbar}>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              className={classes.title}
            >
              日報カレンダー
            </Typography>
          </Toolbar>
        </AppBar>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Typography
              component="h2"
              variant="h5"
              color="inherit"
              noWrap
              className={classes.pageTitle}
            >
              <Button
                variant="outlined"
                color="primary"
                size="large"
                disabled={buttonDisabled}
                onClick={handleLogin}
              >
                Google Sign-In
              </Button>
            </Typography>
          </Container>
        </main>
      </div>
    </ThemeProvider>

  );
};

export default LoginPage;
