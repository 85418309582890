import React from "react";
import css from './ButtonArea.module.css';
import {
  Button,
  PropTypes
} from '@material-ui/core';

export type AreaButton = {
  label: string;
  color?: PropTypes.Color;
  variant?: 'text' | 'outlined' | 'contained'
  clickHandler: () => void;
}

interface ButtonAreaProps {
  buttons: AreaButton[];
}

export const ButtonArea: React.FC<ButtonAreaProps> = (props: ButtonAreaProps): JSX.Element => {
  return (
    <div className={css['button-area']} >
      {props.buttons.map((button, idx) =>
        <Button
          key={`buttonArea-button-${idx}`}
          variant={button.variant ?? "text"}
          color={button.color ?? 'default'}
          className={css.button}
          onClick={() => button.clickHandler()}
        >
          {button.label}
        </Button>
      )}
    </div>
  );
};

export default ButtonArea;
