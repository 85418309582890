import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import './palette.css';

async function initApp() {
  const res = await fetch('/__/app/init.json');
  window.App = {
    config: await res.json(),
  };
}

async function initFirebase() {
  const res = await fetch('/__/firebase/init.json');
  const raw = await res.json();
  // authDomainをカスタムドメイン対応させるために、閲覧中のホスト名を使う
  firebase.initializeApp({
    ...raw,
    authDomain: window.location.host,
  });
}

(async () => {
  await Promise.all([
    initApp(),
    initFirebase(),
  ]);

  ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById("root")
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
